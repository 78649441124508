import Router from "next/router";
import { format, addDays } from "date-fns";
import config from "../config/config.json";
import static_config from "../config/static_config.json";


const commonFunc = {
  getHeaders: () => {
    let csrUrl = localStorage.getItem("csrurl");
    return {
      "Content-Type": "application/json",
      "csrUrl": csrUrl
    };
  },
  getFormatedDate: (date) => {
    let result = date?.split(' ')?.splice(0, 1)?.join('')?.split("-")?.map((ele) => {
      return parseInt(ele)
    })
    return new Date(result?.join("-"))
  },
  getSentenceCase: (sentence) => {
    return sentence.replace(/.+?([.!?]\s|$)/g, (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase());
  },
  getClassName: (...params) => {
    return [...params].join(" ")
  },
  getbaseURL: () => {
    let url = window.location.href;
    let urls = url.includes("/hotels");
    var baseUrl = window.location.origin;
    if (urls) {
      baseUrl += "/hotels";
    }
    return baseUrl;
  },
  paxInfo: function (data) {
    let adults = 0;
    let children = 0;
    let roomsPeopleInfo = [];
    if (data && Array.isArray(data)) {
      data.forEach((room) => {
        let splitpeople = room ? room.split("-") : "";
        adults += parseInt(splitpeople[0][0]);
        children += parseInt(splitpeople[1][0]);

        let ages = [];
        var childSplit = splitpeople[2];
        if (childSplit !== "" && splitpeople[2] !== undefined) {
          childSplit = splitpeople[2].split(",");
          for (const element of childSplit) {
            ages.push({ age: parseInt(element) });
          }
        }

        roomsPeopleInfo.push({
          room: roomsPeopleInfo.length + 1,
          adult: parseInt(splitpeople[0][0]),
          children: parseInt(splitpeople[1][0]),
          childAge: ages,
          adultIncrement: true,
          adultDecrement: true,
          childIncrement: true,
          childDecrement: true,
          disable: false,
        });
      });
    }

    return {
      rooms: roomsPeopleInfo,
      adults: adults,
      childrens: children
    };
  },
  getroomString: function (rooms) {
    let roomString = ""
    rooms && rooms.rooms.forEach((room) => {
      let adult = parseInt(room.adult);
      let child = parseInt(room.children);
      let childAge = '';
      if (room.childAge.length > 0) {
        childAge = room.childAge.reduce(
          (accumulator, currentValue) => accumulator + "," + currentValue.age,
          ""
        ).replace(",", "")
      }
      roomString += '_' + adult + 'a-' + child + 'c-' + childAge
    })
    return roomString.replace("_", "")
  },
  redirect: function (url, query, target = "_self") {
    let querystring = ""
    if (query && Object.keys(query).length) {
      querystring += "?"
      Object.keys(query).forEach((key) => {
        querystring += `${key}=${query[key]}&`
      })
      querystring = querystring.replace(/&([^&]*)$/, '$1')
    }
    window?.open(`${commonFunc.getbaseURL()}${url}${querystring}`, target)
    // Router.push({
    //   pathname: url,
    //   query: query,
    // })
  },
  backToPrev: () => {
    if (window.history.length > 1) {
      window.history.back()
    } else {
      commonFunc.redirect('/')
    }

  },
  handle_CTA_redirection: (type, data) => {
    let urlData
    switch (type) {
      case "EXTERNAL":
        if (data.external_url)
          window.open(data.external_url, "_blank")
        break;

      case "SRP":
        urlData = {
          "region": data.destination_name,
          "mop": "accrual",
          "country": "India",
          "destination_id": data.destination_id,
          "search_type": data.search_type,
          "checkin": format(addDays(new Date(), parseInt(data.check_in)), 'yyyy-MM-dd'),
          "checkout": format(addDays(new Date(), parseInt(data.check_out)), 'yyyy-MM-dd'),
          "rooms": data.pax,
        }
        commonFunc.redirect("/searchdetail", urlData, "_blank")
        break;
      case "HDP":
        urlData = {
          "searchtext": data.destination_name,
          "mop": "accrual",
          "vid": data.destination_id,
          "search_type": data.search_type,
          "checkin": data.check_in,
          "checkout": data.check_out,
          "rooms": data.pax,
          "supplier_id": data.supplier_id || ""
        }
        commonFunc.redirect("/hoteldetails", urlData, "_self")
        break;
      case "LOGIN":
        // commonFunc.toggleLoginPopupEvent(static_config.config_detail)
        commonFunc.toggleLoginPopupEvent(static_config.config_detail);
        break;
      default:
        break;
    }
    return
  },
  get_CTA_redirection: (type, data) => {
    let url = ""
    switch (type) {
      case "EXTERNAL":
        if (data.external_url)
          url = data.external_url
        break;

      case "SRP":
        url = `/hotels/searchdetail?region=${data.destination_name}&mop=accrual&country=India&destination_id=${data.destination_id}&search_type=${data.search_type}&checkin=${format(addDays(new Date(), parseInt(data.check_in)), 'yyyy-MM-dd')}&checkout=${format(addDays(new Date(), parseInt(data.check_out)), 'yyyy-MM-dd')}&rooms=${data.pax}`
        break;
      case "HDP":
        url = `/hotels/hoteldetails?searchtext=${data.destination_name}&mop=accrual&vid=${data.destination_id}&search_type=21&checkin=${format(addDays(new Date(), parseInt(data.check_in)), 'yyyy-MM-dd')}&checkout=${format(addDays(new Date(), parseInt(data.check_out)), 'yyyy-MM-dd')}&rooms=${data.pax}`
        break;

      default:
        break;
    }
    return url
  },
  formatWithLocale: (date, formatStr) => {
    return format(date, formatStr);
  },
  getLocalImagePath: (imgName) => {
    return `/hotels/assets/images/${imgName}`
  },
  replaceUrlParam: (param, value) => {
    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value. 
    queryParams.set(param, value);

    // Replace current querystring with the new one.
    history.replaceState(null, null, "?" + queryParams.toString());
  },
  deleteUrlParam: (param) => {
    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // delete existing parameter value. 
    queryParams.delete(param);

    // Replace current querystring with the new one.
    history.replaceState(null, null, "?" + queryParams.toString());
  },
  getPriceRoundOf: (param) => {
    return param
    // return param.toLocaleString('en-IN', { maximumFractionDigits: 2, currency: 'INR' });
  },
  getPrice: (value) => {
    if (value || value === 0) {
      return parseInt(value).toLocaleString('en-IN')
    }
    return value
  },
  // toggleLoginPopupEvent: (config) => {
  //   document.dispatchEvent(new CustomEvent("toggleLoginPopupEvent", config))
  // },


  
  loginPopup(config) {
    const event = new CustomEvent("toggleLoginPopupEvent", config);
    document.dispatchEvent(event);
},

toggleLoginPopupEvent(config) { // Use a regular function so 'this' works properly
  commonFunc.loginPopup(config);
},



  closePopup: (setterFunc) => {
    document.querySelector("body").style.overflow = "auto"
    return setterFunc(false)
  },
  redirectToFlight: () => {
    window.open(config.flightUrl, "_self")
  },
  getCapitalizeWords: (inputString) => {
    return inputString.toLowerCase().replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  },
  imageLoadSvg: (w, h) => (
    `<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <linearGradient id="g">
          <stop stop-color="#f5f5f5" offset="20%" />
          <stop stop-color="#f5f5f5" offset="50%" />
          <stop stop-color="#f5f5f5" offset="70%" />
        </linearGradient>
      </defs>
      <rect width="${w}" height="${h}" fill="#ebebeb" />
      <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
      <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
    </svg>`
  ),
  toBase64: (str) => (
    typeof window === "undefined"
      ? Buffer.from(str).toString("base64")
      : window.btoa(str)),
  imageLoader: (width, height) => {
    return `data:image/svg+xml;base64,${commonFunc.toBase64(commonFunc.imageLoadSvg(width, height))}`
  },
  detectCrawler: function (data) {
    var botPattern = "(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|FeedFetcher-Google|Google-Read-Aloud|DuplexWeb-Google|googleweblight|Storebot-Google|APIs-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
    var re = new RegExp(botPattern, 'i');
    var userAgent = data;
    var result = false;
    if (re.test(userAgent)) {
      result = true;
    }
    return result;
  }

}

module.exports = commonFunc

