import '../styles/globals.css';
import Layout from '@/components/common/Layout'
import Motion from '@/components/FramerMotion/Motion';
import { Theme } from '@vernost_dev/vms-react-components/dist/Theme/';
import { Fragment, useEffect } from 'react';

function MyApp({ Component, pageProps }) {
 useEffect(() => {
   const logoutSuccessEvent = () => {
     console.log("reload the page");
     window.location.reload();
   };

   const loginSuccessEvent = () => {
     console.log("Login success event caught");
     console.log("reload the page");
     window.location.reload();
   };

   const sessionTokenExpiredEvent = () => {
     console.log("Session expired event caught");
     // Add session expired functionality here
    //  window.location.reload();
   };

   window.addEventListener("logoutSuccessEvent", logoutSuccessEvent);
   window.addEventListener("loginSuccessEvent", loginSuccessEvent);
   document.addEventListener(
     "sessionTokenExpiredEvent",
     sessionTokenExpiredEvent,
     true
   );

   return () => {
     window.removeEventListener("logoutSuccessEvent", logoutSuccessEvent);
     window.removeEventListener("loginSuccessEvent", loginSuccessEvent);
     document.removeEventListener(
       "sessionTokenExpiredEvent",
       sessionTokenExpiredEvent,
       true
     );
   };
 }, []);



  return (<Fragment>
    <Theme>
      {/*<Motion isCrawler={pageProps?.iscrawler}>*/}
      <Layout isMobile={pageProps?.isMobile} isCrawler={pageProps?.iscrawler} hideFooter={pageProps?.hideFooter}>
        <Component {...pageProps} />
      </Layout>
      {/*</Motion>*/}
    </Theme>
  </Fragment>)
}

export default MyApp;