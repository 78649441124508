import React from 'react'
import Image from 'next/image';
import { useEffect, useState } from "react";
import { getClassName, getLocalImagePath, redirect } from '../../common_js/common';
import config from "../../config/config.json"

function Footer(props) {
  const [mounted, setMounted] = useState(true);

  return (
    mounted ? <>
      {props.isMobile ?
        <div className={getClassName('ig_footer_main_container', 'ig_footer_main_mobile_container')}>
          <div className={['ig_top_footer_container']}>
            <div className={['ig-container']}>
              <div className={getClassName(['ig_top_footer'], ['footer_links'])}>
                <div className={['ig_top_footer_left']}>
                  <ul className={['ig_top_footer_list']}>
                    <li><p><a href={config.footer_links.termsandconditions} target="_blank" className='ig_link'>Terms of Use</a></p></li>
                    <li><p><a href={config.footer_links.faq} target="_blank" className='ig_link'>FAQs</a></p></li>
                    <li><p><a href={config.footer_links.privacy_policy} target="_blank" className='ig_link'>Privacy Policy</a></p></li>
                    <li><p><a href={config.footer_links.contactus} target="_blank" className='ig_link'>Contact Us</a></p></li>
                  </ul>
                </div>
              </div>

              <div className={['ig_top_footer']}>
                <div className={['ig_middle_footer_container']}>
                  <div className={['ig_top_footer_left']}>
                    {/* <div className={['logo_img']}>
                          <Image src={getLocalImagePath('IndiGo_Logo.svg')} alt='indigo' width={100} height={100} style={{ width: '100%', height: 'auto', maxHeight: '100%' }} />
                        </div> */}
                  </div>
                  <div className={['ig_top_footer_right']}>
                    <p className={['operated_text']}>Operated and managed by     <span><Image src={getLocalImagePath('vernost_logo_white.png')} alt='indigo' width={100} height={100} style={{ height: 'auto', maxHeight: '100%', marginLeft: '4px' }} unoptimized /></span>
                    </p>


                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={['ig_bottom_footer_container']}>
            <div className={['ig-container']}>
              <div className={['ig_bottom_footer']}>
                <p className={['copywrite_text']}>
                  Copyright © 2024. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
        :
        <div className={['ig_footer_main_container']}>
          <div className={['ig_top_footer_container']}>
            <div className={['ig-container']}>
              <div className={['ig_top_footer']}>
                <div className={['ig_top_footer_left']}>
                  {/* <div className={['logo_img']}>
                        <Image src={getLocalImagePath('IndiGo_Logo.svg')} alt='indigo' width={100} height={100} style={{ width: '100%', height: 'auto', maxHeight: '100%' }} />
                      </div> */}

                  <ul className={['ig_top_footer_list']}>
                    <li><p><a href={config.footer_links.termsandconditions} target="_blank" className='ig_link'>Terms of Use</a></p></li>
                    <li><p><a href={config.footer_links.faq} target="_blank" className='ig_link'>FAQs</a></p></li>
                    <li><p><a href={config.footer_links.privacy_policy} target="_blank" className='ig_link'>Privacy Policy</a></p></li>
                    <li><p><a href={config.footer_links.contactus} target="_blank" className='ig_link'>Contact Us</a></p></li>
                  </ul>
                </div>
                <div className={['ig_top_footer_right']}>
                  <p className={['operated_text']}>Operated and managed by 
                    <span className={['logo_img']}>  <Image src={getLocalImagePath('vernost_logo_white.png')} alt='indigo' width={100} height={100} style={{ height: 'auto', maxHeight: '100%', marginLeft: '4px' }} unoptimized /></span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={['ig_bottom_footer_container']}>
            <div className={['ig-container']}>
              <div className={['ig_bottom_footer']}>
                <p className={['copywrite_text']}>
                  Copyright © 2024. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div >


      }
    </> : null
  )
}

export default Footer