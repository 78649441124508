const theme_desktop = {
  mode: "main",
  palette: {
    primary: {
      main: "#001B94",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#027bff0d",
      contrastText: "#ffffff",
    },
    error: {
      main: "#d32f2f",
      contrastText: "#ffffff",
    },
    success: {
      main: "green",
      contrastText: "#ffffff",
    },
    warning: {
      main: "orange",
      contrastText: "#ffffff",
    },
    info: {
      main: "blue",
      contrastText: "#ffffff",
    },
  },
  shape: {
    borderRadius: "8px 8px",
  },
  typography: {
    fontFamily: "Roboto",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    title: {
      fontSize: "18px",
      fontWeight: "600",
    },
    heading: {
      fontSize: "24px",
      fontWeight: "800",
    },
    text: {
      fontSize: "14px",
      fontWeight: "400",
    },
  },
};

module.exports = theme_desktop