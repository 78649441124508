"use client";
import { Fragment } from "react";
import { motion } from "framer-motion";

const Motion = ({ children, isCrawler }) => {
    return (
        <Fragment>
            <motion.div
                id="motion_div"
                initial={isCrawler ? false : { y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 0.75 }}
                onAnimationComplete={() => {
                    const animatedDiv = document.querySelector('#motion_div');
                    if (animatedDiv) {
                        let childNodes = animatedDiv.childNodes;
                        childNodes.forEach((child) => {
                            animatedDiv.parentNode.insertBefore(child, animatedDiv);
                        });
                        animatedDiv.parentNode.removeChild(animatedDiv);
                    }
                }}
            >
                {children}
            </motion.div>
        </Fragment>
    );
};

export default Motion;
