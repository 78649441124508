import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Footer from "./Footer";

export default function Layout(props) {
  const { children, isMobile, isCrawler, hideFooter } = props;
  return (
    <div className='ig-homepage'>
      <div className='ig-home-container'>
        {children}
      </div>
      {hideFooter ? null : <Footer isMobile={isMobile} isCrawler={isCrawler} />}
    </div>
  );
}